<template>
  <div class="list-manage order-manage">
    <section class="list-manage-table">
      <div class="table-title">
        <span class="title">订单管理 </span>
        <span class="order-number" v-show="tabsCurrent !== 5"
          >订单数：{{ this.total.totalRow }}，总计：<small>¥</small
          ><big>{{ sum }}</big></span
        >
      </div>
      <div class="tabs-bar">
        <div
          class="tabs-item"
          :class="{ selected: index == tabsCurrent }"
          v-for="(item, index) in tabsList"
          :key="index"
          @click="tabsChange(index)"
        >
          {{ item }}
        </div>
        <div class="month-dropdown" v-if="tabsCurrent !== 5">
          <el-dropdown
            placement="bottom"
            trigger="click"
            @command="
              (command) => {
                chose(command);
              }
            "
          >
            <span class="el-dropdown-link">
              {{ choice }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">全部</el-dropdown-item>
              <el-dropdown-item command="2">本月</el-dropdown-item>
              <el-dropdown-item command="3">上月</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="thead">
        <span class="th">设备</span>
        <span class="th">租赁方式</span>
        <span class="th">时长</span>
        <span class="th">数量</span>
        <span class="th">小计</span>
        <span class="th" v-if="tabsCurrent == 5">操作</span>
      </div>
      <div class="table-title2">
        <div class="device-map" >
            <input type="text" v-model="queryInfo.name" placeholder="请输入关键词搜索" class="input"/>
            <div class="btn-search">搜索</div>
        </div>
      </div>
      <div class="tbody" v-loading="loading">
        <template v-if="tabsCurrent !== 5">
          <div v-if="this.managelist.length !== 0">
            <template v-for="index in managelist">
              <div
                class="list-item"
                :key="index.order_id"
                v-if="index.length > 0"
              >
                <div class="list-item-header" @click="goOrderDetails(index)">
                  <div class="order-number">
                    订单号：{{ index[0].order_id
                    }}<span class="supplier"
                      >{{ index[0].company_name }} 等<i class="f-orange">{{
                        index[0].company_num
                      }}</i
                      >供应商</span
                    >
                  </div>
                  <span class="status">{{
                    index[0].order_state == 1
                      ? "已取消"
                      : index[0].order_state == 2
                      ? "待确认"
                      : index[0].order_state == 3
                      ? "预约成功"
                      : index[0].order_state == 4
                      ? "预约失败"
                      : index[0].order_state == 5
                      ? "结算申请中"
                      : index[0].order_state == 6
                      ? "结算成功"
                      : index[0].order_state == 7
                      ? "结算失败"
                      : index[0].order_state == 8
                      ? "完结申请中"
                      : index[0].order_state == 9
                      ? "完结失败"
                      : "已完结"
                  }}</span>
                </div>
                <div class="list-item-main">
                  <div class="td device" @click="goOrderDetails(index)">
                    <el-image
                      :src="index[0].show_img"
                      style="width: 60px; height: 60px; margin-right: 11px"
                    >
                      <div slot="error" class="image-slot">
                        <img
                          src="~@/assets/images/temp/temp_03.png"
                          class="device-pic"
                          alt=""
                        />
                      </div> </el-image
                    >项目名称：{{ index[0].name }}
                  </div>
                  <div class="td">
                    {{ index[0].rental_fee }}元/{{
                      index[0].rental_type == 0
                        ? "日"
                        : index[0].rental_type == 1
                        ? "月"
                        : index[0].rental_type == 2
                        ? "半年"
                        : "年"
                    }}
                  </div>
                  <div class="td">
                    {{ index[0].rental_time
                    }}{{
                      index[0].rental_type == 0
                        ? "日"
                        : index[0].rental_type == 1
                        ? "月"
                        : index[0].rental_type == 2
                        ? "半年"
                        : "年"
                    }}
                  </div>
                  <div class="td">×{{ index[0].num }}</div>
                  <div class="td">
                    共{{ index[0].device_num }}台设备，预约{{ index[0].rental_time
                    }}{{
                      index[0].rental_type == 0
                        ? "日"
                        : index[0].rental_type == 1
                        ? "月"
                        : index[0].rental_type == 2
                        ? "半年"
                        : "年"
                    }}<br />预计：<span class="f-orange"
                      >¥{{
                        index[0].estimate_rental_fee
                      }}</span
                    >
                    <div
                      style="
                        margin-left: 10px;
                        display: flex;
                        padding-top: 20px;
                      "
                    >
                      <el-button
                        size="small"
                        style="margin-left: 10px"
                        @click="centerDetails(index)"
                        v-if="
                          (index[0].modified_order_state == 0 &&
                            index[0].order_state == 3) ||
                          index[0].order_state == 6 ||
                          index[0].order_state == 7 ||
                          index[0].order_state == 9
                        "
                        >中期结算</el-button
                      >
                      <el-button
                        size="small"
                        style="margin-left: 10px"
                        @click="OrderDetails(index)"
                        v-if="
                          (index[0].modified_order_state == 0 &&
                            index[0].order_state == 3) ||
                          index[0].order_state == 6 ||
                          index[0].order_state == 7 ||
                          index[0].order_state == 9
                        "
                        >订单完结</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div
            v-else-if="managelist.length === 0 && !loading"
            style="text-align: center"
          >
            暂无数据
          </div>
        </template>
        <template v-else>
          <template v-if="modifyOrder.length !== 0"
            ><div
              class="list-item"
              v-for="modifyorder in modifyOrder"
              :key="modifyorder.order_id"
            >
              <div class="list-item-header">
                <span class="order-number"
                  >订单号：{{ modifyorder.order_id }}</span
                ><span class="status">{{
                  modifyorder.modified_order_state == 1
                    ? "修改待确认"
                    : modifyorder.modified_order_state === 3
                    ? "修改成功"
                    : "修改失败"
                }}</span>
              </div>

              <div
                class="list-item-main"
                v-for="item in modifyorder.order_info"
                :key="item.order_info_id"
              >
                <div class="td device">
                  <img :src="item.show_img" class="device-pic" alt="" />{{
                    item.name
                  }}
                </div>
                <div class="td" style="position: relative; right: 25px">
                  {{ item.rental_fee }}元/{{
                    item.rental_type == 0
                      ? "日"
                      : item.rental_type == 1
                      ? "月"
                      : item.rental_type == 2
                      ? "半年"
                      : "年"
                  }}
                </div>
                <div class="td" style="position: relative; right: 65px">
                  {{ item.rental_time
                  }}{{
                    item.rental_type == 0
                      ? "日"
                      : item.rental_type == 1
                      ? "月"
                      : item.rental_type == 2
                      ? "半年"
                      : "年"
                  }}
                </div>
                <div class="td" style="position: relative; right: 65px">
                  ×{{ item.num }}
                </div>
                <div class="td">
                  <div class="textcontent">
                    <div
                      class="sub-total"
                      style="position: relative; right: 55px"
                    >
                      共{{ item.num }}台设备，预约{{ item.rental_time
                      }}{{
                        item.rental_type == 0
                          ? "日"
                          : item.rental_type == 1
                          ? "月"
                          : item.rental_type == 2
                          ? "半年"
                          : "年"
                      }}
                      <div class="tar">
                        预计：<span class="f-orange"
                          >¥{{
                            item.num * item.rental_fee * item.rental_time
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="twobtn" style="position: relative; top: -37px">
                    <div class="btns">
                      <span
                        class="btn btn-orange"
                        @click="action(item, modifyorder, 1)"
                        :style="
                          tabsCurrent === 5 &&
                          modifyorder.modified_order_state === 1
                            ? ''
                            : 'display:none'
                        "
                        >确认修改</span
                      ><span
                        class="btn"
                        @click="action(item, modifyorder, 0)"
                        :style="
                          tabsCurrent === 5 &&
                          modifyorder.modified_order_state === 1
                            ? ''
                            : 'display:none'
                        "
                        >拒绝修改</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </section>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="page.pageSize"
        :current-page="page.pageNumber"
        :total="total.totalRow"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrder, ModifyOrder, update_order1 } from "../../api/myorder";
import kits from "@/kits/index.js";
export default {
  data() {
    return {
      loading: true,
      tabsCurrent: 0,
      tabsList: [
        "全部",
        "申请待确认",
        "预约成功",
        "结算",
        "已完结",
        "订单修改",
      ],
      managelist: [],
      numlist: [],
      list: [],
      listall: [],
      sum: "",
      queryInfo:{},
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: {
        totalPage: null,
        totalRow: null,
      },
      startobj: "",
      endobj: "",
      id: "",
      choice: "全部",
      choiceNum: 1,
      // A: 全部 B: 本月 C:上月
      listA: [],
      listB: [],
      listC: [],
      modifyOrder: [],
      modifiedState: [],
    };
  },
  created() {
    this.getmyorder();
    this.getmonth();
    this.getlastmonth();
  },
  methods: {
    action(item, rental, flag) {
      console.log("confirm", rental, flag);
      let modified_order_state;
      if (rental.modified_order_state === 1 && flag === 1) {
        modified_order_state = 3;
      } else if (rental.modified_order_state === 1 && flag === 0) {
        modified_order_state = 4;
      } else if (rental.modified_order_state === 3) {
        this.$message({
          message: "后台审核中，请稍后再试",
          type: "warning",
        });
        return;
      }
      this.$confirm(`确定${flag === 1 ? "确认" : "拒绝"}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          update_order1({
            inputs: {
              order_id: item.order_id,
              modified_order_state: modified_order_state,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                this.$message({
                  message: `${flag === 1 ? "确认" : "拒绝"}成功`,
                  type: "success",
                });
                this.ModifyOrder();
              } else if (res.status === 500) {
                this.$message({
                  message: `未知错误，请联系客服`,
                  type: "error",
                });
              } else {
                this.$message({
                  message: `${flag === 1 ? "确认" : "拒绝"}失败`,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: `${err}`,
                type: "error",
              });
            });
        })
        .catch(() => {
          console.log("取消成功");
        });
    },
    centerDetails(index) {
      this.$router.push({
        path: `/personalCenter/orderCompleted`,
        query: { id: index[0].order_id, button_id: 0 },
      });
    },
    OrderDetails(index) {
      this.$router.push({
        path: `/personalCenter/orderCompleted`,
        query: { id: index[0].order_id, button_id: 1 },
      });
    },
    goOrderDetails(index) {
      this.$router.push({
        path: `/personalCenter/orderDetails`,
        query: { id: index[0].order_id },
      });
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val;
      this.getlist();
      window.scrollTo(0, 0);
    },

    getlist() {
      if (this.tabsCurrent == 1) {
        this.tabChange1(this.tabsCurrent);
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 2) {
        this.tabChange2(this.tabsCurrent);
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 3) {
        this.tabChange3(this.tabsCurrent);
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 4) {
        this.tabChange4(this.tabsCurrent);
        window.scrollTo(0, 0);
      } else if (this.tabsCurrent == 5) {
        this.ModifyOrder();
        window.scrollTo(0, 0);
      } else {
        this.confirmorder();
        window.scrollTo(0, 0);
      }
    },
    confirmorder() {
      if (this.choiceNum == 1) {
        this.getmyorder();
      } else if (this.choiceNum == 2) {
        this.monthorder();
      } else {
        this.lastorder();
      }
    },
    getdata(res) {
      this.sum = res.data.estimate_rental_fee;
      this.managelist = res.data.order_list;
      this.total.totalRow = res.data.total;
    },
    async tabChange1() {
      if (this.choiceNum == 1) {
        let res = await getOrder({
          inputs: { order_state: [2, 4] },
          pagination: this.page,
        });

        console.log("待确认", res.data.order_list);
        this.getdata(res);

        this.imgs();
      } else if (this.choiceNum == 2) {
        this.getmonth();
        this.confirmorder1();
      } else if (this.choiceNum == 3) {
        this.getlastmonth();
        this.confirmorder1();
      }
    },
    async confirmorder1() {
      let res = await getOrder({
        inputs: {
          order_state: [2, 4],
          start_date: this.startobj,
          end_date: this.endobj,
        },
        pagination: this.page,
      });

      this.getdata(res);
      this.imgs();
    },
    async tabChange2() {
      if (this.choiceNum == 1) {
        let res = await getOrder({
          inputs: { order_state: [3] },
          pagination: this.page,
        });

        console.log("预约成功", res);

        this.getdata(res);
        this.imgs();
      } else if (this.choiceNum == 2) {
        this.getmonth();
        this.confirmorder2();
      } else if (this.choiceNum == 3) {
        this.getlastmonth();
        this.confirmorder2();
      }
    },
    async confirmorder2() {
      let res = await getOrder({
        inputs: {
          order_state: [3],
          start_date: this.startobj,
          end_date: this.endobj,
        },
        pagination: this.page,
      });

      this.getdata(res);
    },
    async tabChange3() {
      if (this.choiceNum == 1) {
        let res = await getOrder({
          inputs: { order_state: [5, 6, 7, 8] },
          pagination: this.page,
        });

        console.log("结算", res);

        this.getdata(res);
        this.imgs();
      } else if (this.choiceNum == 2) {
        this.getmonth();
        this.confirmorder3();
      } else if (this.choiceNum == 3) {
        this.getlastmonth();
        this.confirmorder3();
      }
    },
    async confirmorder3() {
      let res = await getOrder({
        inputs: {
          order_state: [5, 6, 7, 8],
          start_date: this.startobj,
          end_date: this.endobj,
        },
        pagination: this.page,
      });

      this.getdata(res);
      this.imgs();
    },
    async tabChange4() {
      if (this.choiceNum == 1) {
        let res = await getOrder({
          inputs: { order_state: [9, 10] },
          pagination: this.page,
        });

        console.log("完结", res);
        this.getdata(res);
        this.imgs();
      } else if (this.choiceNum == 2) {
        this.getmonth();
        this.confirmorder4();
      } else if (this.choiceNum == 3) {
        this.getlastmonth();
        this.confirmorder4();
      }
    },
    async confirmorder4() {
      let res = await getOrder({
        inputs: {
          order_state: [9, 10],
          start_date: this.startobj,
          end_date: this.endobj,
        },
        pagination: this.page,
      });

      this.getdata(res);
      this.imgs();
    },
    tabsChange1() {
      if (this.tabsCurrent == 1) {
        this.tabChange1(this.tabsCurrent);
      } else if (this.tabsCurrent == 2) {
        this.tabChange2(this.tabsCurrent);
      } else if (this.tabsCurrent == 3) {
        this.tabChange3(this.tabsCurrent);
      } else if (this.tabsCurrent == 4) {
        this.tabChange4(this.tabsCurrent);
      } else if (this.tabsCurrent == 5) {
        this.choice = "全部";
        this.choiceNum = 1;
        this.ModifyOrder();
      }
    },
    tabsChange2() {
      if (this.choiceNum == 1) {
        if (this.tabsCurrent == 0) {
          this.getmyorder();
        }
      } else if (this.choiceNum == 2) {
        if (this.tabsCurrent == 0) {
          this.monthorder();
        }
      } else if (this.choiceNum == 3) {
        if (this.tabsCurrent == 0) {
          this.lastorder();
        }
      }
    },
    tabsChange(index) {
      this.page.pageNumber = 1;
      this.tabsCurrent = index;
      this.tabsChange2();
      this.tabsChange1();
    },
    async getmyorder() {
      this.id = this.$route.query.id;
      let res = await getOrder({
        inputs: {},
        pagination: this.page,
      });
      console.log(res);
      for (let i = 0; i < res.data.order_list.length; i++) {
        if (res.data.order_list[i].length == 0) {
          res.data.order_list.splice(i, 1);
        }
      }

      this.getdata(res);
      this.imgs();
      this.loading = false;
    },
    imgs() {
      for (let i = 0; i < this.managelist.length; i++) {
        this.managelist[i][0].show_img = kits.img.url(
          this.managelist[i][0].show_img
        );
      }
    },
    async getmonth() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      this.startobj = year + "-" + month + "-01 00:00:00";
      let days = new Date(year, month, 0).getDate();
      this.endobj = year + "-" + month + "-" + days + " 23:59:59";
    },

    async getlastmonth() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;

      let month2 = month - 1;
      if (month2 == 0) {
        year = year - 1;
        month2 = 12;
      }

      this.startobj = year + "-" + month2 + "-01 00:00:00";
      let days = new Date(year, month2, 0).getDate();
      this.endobj = year + "-" + month2 + "-" + days + " 23:59:59";
    },
    async monthorder() {
      this.getmonth();
      let res = await getOrder({
        inputs: {
          start_date: this.startobj,
          end_date: this.endobj,
        },
        pagination: this.page,
      });
      this.getdata(res);
      this.imgs();
    },
    ModifyOrder() {
      ModifyOrder({ inputs: {}, pagination: this.page }).then((res) => {
        console.log("dasda", res);
        this.getdata(res);
        this.modifyOrder = res.data.order_list;
        for (let i = 0; i < this.modifyOrder.length; i++) {
          for (let j = 0; j < this.modifyOrder[i].order_info.length; j++) {
            var item = this.modifyOrder[i].order_info[j].show_img;
            item = kits.img.url(item);
            this.modifyOrder[i].order_info[j].show_img = item;
          }
        }
      });
    },
    async lastorder() {
      this.getlastmonth();
      let res = await getOrder({
        inputs: {
          start_date: this.startobj,
          end_date: this.endobj,
        },
        pagination: this.page,
      });
      this.getdata(res);
      this.imgs();
    },
    chose1() {
      if (this.choiceNum == 1) {
        this.getmyorder();
      } else if (this.choiceNum == 2) {
        this.monthorder();
      } else if (this.choiceNum == 3) {
        this.lastorder();
      }
    },
    async chose(c) {
      this.page.pageNumber = 1;
      this.choiceNum = c;
      this.choice = c == 1 ? "全部" : c == 2 ? "本月" : "上月";
      if (this.tabsCurrent == 0) {
        this.chose1();
      } else if (this.tabsCurrent == 1) {
        this.tabChange1();
      } else if (this.tabsCurrent == 2) {
        this.tabChange2();
      } else if (this.tabsCurrent == 3) {
        this.tabChange3();
      } else if (this.tabsCurrent == 4) {
        this.tabChange4();
      }
    },
  },
};
</script>

<style scoped>
.twobtn {
  position: relative;
  left: 10px;
  top: 0;
}
.btns {
  display: flex;
  flex-direction: column;
  width: 75px;
  margin-left: 98px;
}
.textcontent {
  display: flex;
  width: 200px;
}
</style>
